<template>
  <pro-menu-layout>
    <div class="q-ma-md">
      <pro-deck
        :title="getRes('Form.Field.SignedNDA')"
        :cards="cards"
        :statuses="statuses"
        v-slot="{ card, status }"
        filter-by-status
      >
        <pro-deck-card
          :title="card.title"
          :date="card.date"
          :actions="card.actions"
          :status="status ? status.label : ''"
          :statusColor="status ? status.color : ''"
        />
      </pro-deck>
    </div>
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";

export default {
  components: { ProMenuLayout, ProDeckCard, ProDeck },
  props: {
    documentStatus: Number,
  },
  data() {
    return {
      cards: [],
      statuses: [
        {
          key: "approved",
          label: this.getRes("Document.Status.Completed"),
          color: "green",
        },
        {
          key: "submitted",
          label: this.getRes("Document.Status.PendingForApproval"),
          color: "orange",
        },
        {
          key: "notSubmitted",
          label: this.getRes("Document.Status.Pending"),
          color: "red",
        },
      ],
      allowAUploadNdaStatus: [50, 80],
    };
  },
  methods: {
    getTendererActions(tenderer, uploadNda) {
      let actions = [];

      if (tenderer.submitted) {
        actions.push({
          key: "view",
          label: this.getRes("System.Button.View"),
          to: {
            name: "DocumentViewNDAResponseDetails",
            params: {
              tendererId: tenderer.tendererId,
              companyName: tenderer.companyName,
            },
          },
        });
      }

      if (
        uploadNda.visible &&
        this.allowAUploadNdaStatus.includes(this.documentStatus)
      ) {
        actions.push({
          key: "upload",
          label: this.getRes("System.Button.Upload"),
          to: {
            name: "DocumentViewTendererForm",
            params: {
              mode: "Edit",
              code: uploadNda.workflowCode,
              tendererId: tenderer.tendererId,
              companyName: tenderer.tendererUserId,
            },
          },
        });
      }

      return actions;
    },
  },
  created() {
    Promise.all([
      this.$proSmart.documentUi.getNdaSubmissionAndStatus(
        this,
        this.$route.params.id
      ),
      this.$proSmart.tender.getWorkflowCode(this, this.$route.params.id, [
        "upload_signed_nda",
      ]),
    ]).then(([tendererList, [uploadNda]]) => {
      this.cards = tendererList.map((tenderer) => ({
        key: tenderer.tendererId,
        title: tenderer.companyName,
        userId: tenderer.tendererUserId,
        status: !tenderer.submitted || (tenderer.submitted && tenderer.status === "Rejected") ? "notSubmitted" : tenderer.status === "Approved" ? "approved" : "submitted",
        date: tenderer.submitted ? new Date(tenderer.dateReceived) : undefined,
        actions: this.getTendererActions(tenderer, uploadNda),
      }));
    });
  },
};
</script>
